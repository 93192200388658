import { Suspense } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from './Routes/AppRouter';
import { IndexedDbInIt } from './Common/IndexedStorage';
import { ThemeProvider } from "@mui/styles";
import theme from "./Styles/theme";
import Loader from './Common/Loader';
import './index.css'
import { CartProvider } from './Common/CartContexts/CartContext';

IndexedDbInIt();

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loader />}>
        <CartProvider>
          <Router>
            <AppRouter />
          </Router>
        </CartProvider>
      </Suspense>
    </ThemeProvider>
  );
}