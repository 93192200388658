
import loadable from '@loadable/component';
import { Switch, Route } from "react-router-dom";
// load pages as needed
const Home = loadable(() => import('../PublicPages/Home/Home'));
const LiteratureList = loadable(() => import('../PublicPages/Literature/LiteratureList'));
const LiteratureView = loadable(() => import('../PublicPages/Literature/View/LiteratureView'));
const Cart = loadable(() => import('../PublicPages/Cart/Cart'));
const CheckOut = loadable(() => import('../PublicPages/CheckOut/CheckOut'));
const TermOfUsePage = loadable(() => import('../Legal/TermOfUsePage'));
const AcceptableUsePolicy = loadable(() => import('../Legal/AcceptableUsePolicy'));
const CookiePolicy = loadable(() => import('../Legal/CookiePolicy'));
const PrivacyPolicy = loadable(() => import('../Legal/PrivacyPolicy'));
const AboutUs = loadable(() => import('../PublicPages/AboutUs/AboutUs'));
const ContactUs = loadable(() => import('../PublicPages/ContactUs/ContactUs'));
const ThankYou = loadable(() => import('../PublicPages/ThankYou/ThankYou'));
const CCAvenuePaymentSuccess = loadable(() => import('../PublicPages/CheckOut/Payment/CCAvenuePaymentSuccess'));
const CCAvenuePaymentFailed = loadable(() => import('../PublicPages/CheckOut/Payment/CCAvenuePaymentFailed'));
const CCAvenuePaymentCancel = loadable(() => import('../PublicPages/CheckOut/Payment/CCAvenuePaymentCancel'));
const ShreePantMaharaj = loadable(() => import('../PublicPages/AboutMaharaj/ShreePantMaharaj'));

export default function AppRouter() {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/Legal/TermsOfUse" component={TermOfUsePage} />
            <Route path="/Legal/AcceptableUsePolicy" component={AcceptableUsePolicy} />
            <Route path="/Legal/CookiePolicy" component={CookiePolicy} />
            <Route path="/Legal/PrivacyPolicy" component={PrivacyPolicy} />
            <Route path="/AboutUs" component={AboutUs} />
            <Route path="/ContactUs" component={ContactUs} />
            <Route path="/Cart" component={Cart} />
            <Route path="/CheckOut" component={CheckOut} />
            <Route path="/CCAvenuePaymentSuccess" component={CCAvenuePaymentSuccess} />
            <Route path="/CCAvenuePaymentFailed" component={CCAvenuePaymentFailed} />
            <Route path="/CCAvenuePaymentCancel" component={CCAvenuePaymentCancel} />
            <Route path="/ShreePantMaharaj" component={ShreePantMaharaj} />
            <Route path="/ThankYou/:requestType" component={ThankYou} />
            <Route path="/Category/:categoryUrl/:pageNo" component={LiteratureList} />
            <Route path="/Category/:categoryUrl" component={LiteratureList} />
            <Route path="/:categoryUrl/:metaUrl" component={LiteratureView} />
        </Switch>
    )
}