import LocalStorage from "../../Common/LocalStorage";
import { LocalStorageKeys } from "../../Common/Constants";

function getProductData() {
    let selectedItems = LocalStorage.GetData(LocalStorageKeys.ProductData);
    let arrSelectedItems = selectedItems ? JSON.parse(selectedItems) : [];
    return arrSelectedItems || [];
}

function setProductData(productDetails) {
    LocalStorage.SetData(LocalStorageKeys.ProductData, productDetails ? JSON.stringify(productDetails) : null);
}

export const CartReducer = (state, action) => {
    if (action.type === "REMOVE_ITEM") {
        return {
            ...state,
            products: state.products.filter((curElem) => {
                let productDetails = getProductData();
                if (productDetails) {
                    let indexPd = productDetails.findIndex((e) => e.Id === action.payload);
                    if (indexPd > -1) {
                        productDetails.splice(indexPd, 1);
                        setProductData(productDetails);
                        //LocalStorage.SetData(LocalStorageKeys.ProductData, JSON.stringify(productDetails));
                    }
                }
                // let selectedProductDetails = LocalStorage.GetData(LocalStorageKeys.ProductData);
                // if (selectedProductDetails) {
                //     let arrSelectedProductDetails = JSON.parse(selectedProductDetails);
                //     let indexPd = arrSelectedProductDetails.findIndex((e) => e.Id === action.payload);
                //     if (indexPd > -1) {
                //         arrSelectedProductDetails.splice(indexPd, 1);
                //         LocalStorage.SetData(LocalStorageKeys.ProductData, JSON.stringify(arrSelectedProductDetails));
                //     }
                // }

                return curElem.Id !== action.payload;
            })
        };
    }

    if (action.type === "CLEAR_CART") {
        localStorage.removeItem(LocalStorageKeys.ProductData);
        return { ...state, item: [], products: [] };
    }

    if (action.type === "INCREMENT") {
        function incr(arrSelectedItems) {
            const index = arrSelectedItems.findIndex((e) => e.Id === action.payload);

            if (index > -1) {
                arrSelectedItems[index].Quantity = arrSelectedItems[index].Quantity + 1;
            }
            else {
                arrSelectedItems.push({ Id: action.payload, Quantity: 1 });
            }
            return arrSelectedItems;
        }

        let productDetails = getProductData();
        let productSelectedData = incr(productDetails);
        //let productSelectedData = incr(LocalStorage.GetData(LocalStorageKeys.ProductData));

        setProductData(productSelectedData);
        //LocalStorage.SetData(LocalStorageKeys.ProductData, JSON.stringify(productSelectedData));

        function updatedCart(items) {
            return items;
        }

        return { ...state, products: updatedCart(productSelectedData) };
    }

    if (action.type === "DECREMENT") {
        function decr(arrSelectedItems) {
            if (!arrSelectedItems || arrSelectedItems.length === 0) {
                return null;
            }

            const index = arrSelectedItems.findIndex((e) => e.Id === action.payload);

            if (index > -1) {
                arrSelectedItems[index].Quantity = arrSelectedItems[index].Quantity > 1 ? arrSelectedItems[index].Quantity - 1 : arrSelectedItems[index].Quantity;
            }

            return arrSelectedItems;
        }

        let productDetails = getProductData();
        let productSelectedData = decr(productDetails);
        setProductData(productSelectedData);
        //LocalStorage.SetData(LocalStorageKeys.ProductData, JSON.stringify(productSelectedData));

        function updatedCart(items) {
            return items;
        }

        return { ...state, products: updatedCart(productSelectedData) };

        // const updatedCart = state.products
        //     .map((curElem) => {
        //         if (curElem.Id === action.payload && curElem.Quantity > 1) {
        //             return { ...curElem, Quantity: curElem.Quantity - 1 };
        //         }
        //         return curElem;
        //     });
        // return { ...state, products: updatedCart };
    }

    if (action.type === "GET_TOTAL") {
        let { totalItem, totalAmount } = state && state.products ? state.products.reduce(
            (accum, curVal) => {
                let { Price } = curVal;
                let updatedTotalAmount = Price * curVal.Quantity;
                accum.totalAmount += updatedTotalAmount;

                accum.totalItem += curVal.Quantity;
                return accum;
            },
            {
                totalItem: 0,
                totalAmount: 0,
            }
        ) : {
            totalItem: 0,
            totalAmount: 0,
        };
        return { ...state, totalItem, totalAmount };
    }

    if (action.type === "GET_TOTALDATA") {
        return {
            ...state,
            products: action.payload.products,
            quantity: action.payload.Quantity
        };
    }

    if (action.type === "SET_ITEM") {
        return {
            ...state,
            products: action.payload
        };
    }

    if (action.type === "SET_PRODUCTS") {
        setProductData(action.payload);
        //LocalStorage.SetData(LocalStorageKeys.ProductData, JSON.stringify(action.payload));

        return {
            ...state,
            products: action.payload
        };
    }

    if (action.type === "GET_TOTAL_ITEM") {
        let totalItem = state && state.products ? state.products.length : 0;
        return { ...state, totalItem };
    }

    return state;
};
