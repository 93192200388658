
export const Dates = {
    longDateTimeDisplayFormat: 'lll',
    shortDatePickerFormat: 'yyyy-MM-dd',
    shortDateDisplayFormat: 'dd-MMM-yyyy',
    shortDateFormat: 'dd/MM/yyyy',
    shortDateTimeFormat: 'dd/MM/yy HH:mm',
}

export const OrgInfo = {
    Name: `ShriPantBodhpeeth`,
    FullName: `ShriPantBodhpeeth`,
    FullNamePvtLtd: `ShriPantBodhpeeth`,
    SalesEmail: 'contactus@shripantbodhpeeth.com'
}

export const LocalStorageKeys = {
    Categories: 'fdsa32dfs',
    CartItem: 'ananabelppa',
    ProductData: 'rebmucuc'
}

export const Paging = {
    PageSize: 10,
    RowSizeOptions: [10, 20, 30, 40, 50]
}

export const GetPageSize = function (pageSize) {
    if (pageSize) {
        return parseInt(pageSize);
    }

    return parseInt(Paging.PageSize);
}

export const ValidationMessages = {
    TnCNotAccepted: 'Please read Terms and conditions carefully and accept it',
    ValidationBlankForm: 'Please fill the form to serve you better',
    ValidationFailed: 'We would require some more information to serve you better',
    ValidationLength: '{PropertyName} should have {MaxLength} letters',
    ValidationMaxLength: '{PropertyName} should be less than {MaxLength} letters',
    ValidationMinLength: '{PropertyName} should be greater than {MinLength} letters',
    ValidationRegex: 'Please Enter Valid {PropertyName}',
    ValidationRequired: 'Please Enter {PropertyName}',
    ValidationSelectionRequired: 'Please Select {PropertyName}',
    ValidationCaptchaFailed: 'Please prove yourself a human being',
    ValidationGreaterThan: '{PropertyName} should be greater than {MinValue}',
    ValidationGreaterThanOrEqualTo: '{PropertyName} should be greater than or equal to {MinValue}',
    ValidationLessThan: '{PropertyName} should be less than {MinValue}',
    ValidationLessThanOrEqualTo: '{PropertyName} should be less than or equal to {MinValue}'
}

export const APIMessages = {
    APIFailMessage: 'We are extreamly sorry. We are facing issues. Will resolve it at earliest',
    APIExceptionMessage: 'We are extreamly sorry. We are facing technical challenges. Team is working on it. Thank you for your patience',
}

export const CartMessages = {
    ProductAddedMessage: 'Item added successfully, go to shopping cart',
    ProductExistMessage: 'Item already added, go to shopping cart',
}

export const Charges = {
    Shipping: {
        Price: 150,
        Name: 'Postage',
        Name_MR: 'पोस्टेज'
    }
}

export const Currency = {
    INR: {
        Symbol: '₹',
        Code: 'INR',
        Name: 'Rupee',
        PluralName: 'Rupees'
    }
}

export const PaymentTypes = {
    WireTransfer: { id: 1, label: 'Scan & Pay', name: 'WireTransfer' },
    PayPal: { id: 2, label: 'Pay Pal', name: 'PayPal' },
    CreditCard: { id: 3, label: 'Credit Card', name: 'CreditCard' },
    DebitCard: { id: 4, label: 'Debit Card', name: 'DebitCard' },
    RazorPay: { id: 5, label: 'Razor Pay', name: 'RazorPay' },
    Amex: { id: 6, label: 'Amex', name: 'Amex' },
    CCAvenue: { id: 7, label: 'CC venue', name: 'CCAvenue' }
}

export const Contacts = {
    IN: { Contact1: '93721 91740', Title: 'Mobile', Address: { Title: 'Address.Title', Details: 'Address.Details' } }
}

export const RequestTypes = [
    { id: 1, popupId: 2, name: 'requestBuy', type: 'Buy', displayName: 'Buy', submitButtonName: 'Submit Buy Enquiry', requestText: 'Buy Literature request', thankYouText: 'Our sales experts will work on this and deliver it shortly.' },
    { id: 3, popupId: 4, name: 'ContactUs', type: 'ContactUs', displayName: 'Contact Us', submitButtonName: 'Send', requestText: 'Contact Us request', thankYouText: 'Our team will contact you shortly' },
]

export const SessionStorageKeys = {
    Request: 'kdsfjkds'
}

export const Security = {
    SecurityEDKey: "SDG Threeng"
}
