import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: [
            'Mukta',
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontWeightRegular: '500'
    },
    palette: {
        primary: {
            light: '#ee014824',
            main: '#EE0148',
            dark: '#ee0148',
            contrastText: '#FFFFFF'
        },
        secondary: {
            light: '#f9d8b5',
            main: '#F1F4F0',
            dark: '#1743f1',
            contrastText: '#000'
        }
    },
    custom: {
        hoverColor: '#f9d8b5',
        textColor: '#000000',
        hightlightTextColor: '#375EF9',
        greyText: '#747579',
        borderColor: '#707070'
    }
});

export default theme;