import React from 'react';
import { makeStyles } from '@mui/styles';
import { LinearProgress, CircularProgress, Box } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        marginTop: (props) => props.marginTop
    },
}));

const laoderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

export default function Loader(props) {
    const classes = useStyles(props);
    const progressLines = props?.lines ? parseInt(props.lines) : 3;
    const rounded = props?.rounded ? true : false;
    const roundedSize = props?.roundedSize ? props.roundedSize : 40;
    const roundedStyle = props?.roundedStyle ? props.roundedStyle : null;
    const loaderStyle = props?.loaderStyle ? props.loaderStyle : null;

    function showProgressLines() {
        return (
            <>
                {
                    Array.from(Array(progressLines), (e, i) => {
                        return <LinearProgress key={i} />
                    })
                }
            </>
        )
    }

    function ShowLoaderImage() {
        return (
            <Box sx={laoderStyle}>
                <Box component="img"
                    sx={{
                        height: '100%',
                        width: '100%'
                    }}
                    alt="Book Opening"
                    src="/Images/Loader.gif"
                />
            </Box>
        )
    }

    if (rounded) {
        return (
            <span className={classes.root} style={roundedStyle}>
                <CircularProgress size={roundedSize} style={loaderStyle} />
            </span>
        )
    }

    return (
        <div className={classes.root}>
            <ShowLoaderImage />
        </div>
    );
}