import { clear, set, get, promisifyRequest } from 'idb-keyval';

const StoreNames = {
    Profile: 'idsaklejc',
    LeadUpsertTab: 'jikdosajfke'
}

const write = (key, data) => {
    return new Promise((resolve, reject) => {
        set(key, data).then(
            event => {
                return resolve(event);
            },
            error => {
                return reject(error);
            }
        )
    })
}

const read = (key) => {
    return new Promise((resolve, reject) => {
        get(key)
            .then(
                data => {
                    return resolve(data);
                },
                error => {
                    return reject(error);
                }
            );
    })
}

const IndexedDbInIt = () => {
    promisifyRequest(indexedDB.deleteDatabase('SDG'))
}

const ClearAll = (key) => {
    if (key)
        clear(key);
    else
        clear();
}

export { IndexedDbInIt, write, read, StoreNames, ClearAll }