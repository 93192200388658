import { createContext, useState, useContext, useEffect, useReducer } from 'react';
import { LocalStorageKeys } from '../Constants';
import { LocalStorage } from '../../Common/CommonUtils';
import { CartReducer } from './CartReducer';

const initialState = {
    item: [],
    products: [],
    totalAmount: 0,
    totalItem: 0,
};

//Create the Auth Context with the data type specified
//and a empty object
const CartContext = createContext();

const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CartReducer, initialState);

    //useEffect to update the data
    useEffect(() => {
        dispatch({ type: "GET_TOTAL_ITEM" });
    }, [state.products]);

    useEffect(() => {
        let selectedProduct = LocalStorage.GetData(LocalStorageKeys.ProductData);

        let arrSelectedProduct = selectedProduct ? JSON.parse(selectedProduct) : [];
        arrSelectedProduct = arrSelectedProduct || [];

        dispatch({
            type: "SET_ITEM",
            payload: arrSelectedProduct,
        });
    }, [])

    const removeItem = (id) => {
        dispatch({
            type: "REMOVE_ITEM",
            payload: id,
        });
        return dispatch({
            type: "GET_TOTAL"
        });
    };

    // clear the cart
    const clearCart = () => {
        localStorage.removeItem(LocalStorageKeys.ProductData);
        return dispatch({ type: "CLEAR_CART" });
    };

    // increment the item
    const increment = (id) => {
        dispatch({
            type: "INCREMENT",
            payload: id,
        });
        return dispatch({
            type: "GET_TOTAL"
        });
    };

    // decrement the item
    const decrement = (id) => {
        return dispatch({
            type: "DECREMENT",
            payload: id,
        });
    };

    const setProducts = (products) => {
        dispatch({
            type: "SET_PRODUCTS",
            payload: products,
        });

        return dispatch({
            type: "GET_TOTAL"
        });
    };


    return (
        //This component will be used to encapsulate the whole App,
        //so all components will have access to the Context
        <CartContext.Provider value={{ ...state, removeItem, clearCart, increment, decrement, setProducts }}>
            {children}
        </CartContext.Provider>
    )
}

//A simple hooks to facilitate the access to the CartContext
// and permit components to subscribe to CartContext updates
function useCart() {
    try {
        const context = useContext(CartContext);
        if (!context) {
            throw new Error('useCart must be used within an CartProvider');
        }

        return context;
    }
    catch (err) {
        console.log('error in context: ' + err);
    }
}

export { CartContext, CartProvider, useCart }

