import moment from 'moment';

const storageDateFormat = 'LLL'
function ValidateStorageData(key) {
    let updateKey = `${key}UpdatedDate`;
    let updatedDate = localStorage.getItem(updateKey);
    let isToday = updatedDate ? moment().isSame(moment(updatedDate, storageDateFormat), 'day') : false;

    if (isToday) {
        return;
    }

    localStorage.removeItem(key);
    localStorage.setItem(updateKey, moment().format(storageDateFormat));
}

function SetStorageUpdatedDate(key) {
    let updateKey = `${key}UpdatedDate`;
    localStorage.setItem(updateKey, moment().format(storageDateFormat));
}

function SetData(key, data) {
    if (!key || key === 'undefined')
        return;

    SetStorageUpdatedDate(key);
    localStorage.setItem(key, data);
}

function GetData(key) {
    if (!key || key === 'undefined')
        return;

    ValidateStorageData(key);
    return localStorage.getItem(key);
}

export default { SetData, GetData }